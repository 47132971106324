import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import Layout from "../layout";
// import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';

import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import './drinkers.scss'
import EditUser from './drinkers/editUser';
import AddUser from './drinkers/addUser';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Drinkers = () => {

    
    const formatter = buildFormatter(germanStrings)

    const [drinkers, setDrinkers] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [editedUser, setEditedUser] = useState({
        id: 0,
        name: "",
        email: "",
        mobile: "",
        role: 0,
    });

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            // format value to capitalize first letter
            valueFormatter: (val) => { return val.value.charAt(0).toUpperCase() + val.value.slice(1) },
            // width: 100,
            flex: 1,
        },
        {
            field: 'bilanz_total',
            headerName: 'Guthaben',
            type: 'number',
            valueFormatter: (val) => {return 'CHF ' + val.value },
            // width: 130,
            flex: 1,
        },
        {
            field: 'last_transaction_timestamp',
            headerName: 'letzte Aktivität',
            type: 'number',
            valueFormatter: (val) => { 
                const date = new Date(val.value);
                return date.toLocaleDateString();
             }
                // return(
                //    <TimeAgo date={ val.value } formatter={formatter} />
                // );
            ,
            // width: 160,
            flex: 1,
        }
      ];

    useEffect(() => {
        
        getUsersFromParty();

    }, []);


    const getUsersFromParty = () => {
        axios
        .get('/api/user/getUsersFromParty')
        .then((response) => {
            console.log(response.data);
            setDrinkers(response.data);
        })
        .catch((error) => {

        });
    }

    const handleCellClick = (e) => {
        // console.log(e.row);
        setEditedUser(e.row);
        setOpenEdit(true);
        console.log(editedUser);

    }

    const handleClose = () => {
        setOpenEdit(false);
        setEditedUser({
            id: 0,
            name: "",
            email: "",
            mobile: "",
            role: 0,
        });
        getUsersFromParty();
    }

    const handleAddClose = () => {
        setOpenAdd(false);
        getUsersFromParty();
    };

    return(
        <Layout title="Biertrinkende">
            <div id="drinkers">
                <DataGrid
                    rows={drinkers}
                    columns={columns}
                    // autoPageSize={true}
                    // hideFooterPagination={true}
                    pageSize={100}
                    headerHeight={40}
                    rowHeight={40}
                    onCellClick={ handleCellClick }
                />
            </div>

            <Fab
                color="primary"
                aria-label="add"
                onClick={ () => { setOpenAdd(true) } }
                id="plus"
            >
                +
            </Fab>

            <AddUser open={openAdd} onClose={ handleAddClose } />

            <EditUser open={ openEdit } editedUser={ editedUser } onClose={ handleClose } />
        </Layout>
    );
}

export default Drinkers;