import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { stringify } from 'qs';
import RoleSelect from './RoleSelect.js';


import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import SearchIcon from '@material-ui/icons/Search';
import './addUser.scss';

import Quicksearch from './quicksearch.js';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const AddUser = ({open, onClose}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [role, setRole] = useState(3);
    // const [query, setQuery] = useState("");

    // const roleNames = [
    //     'Archiviert',
    //     'Beobachter',
    //     'Trinker',
    //     'Barkeeper',
    //     'Admin',
    //     'Entwickler'
    // ]

    const onSave = () => {

        axios
        .post('/api/user/createUser', stringify({
            name: name,
            role: role,
            mobile: mobile,
            email: email,
        }))
        .then((response) => {
            onClose();
        })
        .catch((error) => {

        });

    }

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {

    //         // search the user
    //         axios
    //         .get(`/api/user/searchUser?query=${query}`)
    //         .then((response) => {
    //             console.log(response.data);
    //         })
    //         .catch((error) => {});

    //     }, 500);
    //     return () => clearTimeout(timeoutId);
    //   }, [query]);

    return(
        <Dialog
                open={open} 
                onClose={onClose} 
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Trinker hinzufügen</DialogTitle>
                <DialogContent id="quicksearch">
                    <Quicksearch onClose={ onClose } />
                </DialogContent>

                <DialogTitle id="form-dialog-title">Neuer Trinker erstellen</DialogTitle>
                <DialogContent>
                    
                    {/* <DialogContentText>
                        Neuer Trinker erstellen
                    </DialogContentText> */}

                    {/* <InputLabel id="demo-simple-select-label">Rolle</InputLabel> */}
                    {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ role }
                        onChange={ (e) => setRole(e.target.value) }
                        >
                            {
                                roleNames.map((roleName, index) => {
                                    return(
                                        <MenuItem
                                            value={ index + 1 }
                                            key={ index }
                                        >
                                            { roleName }
                                        </MenuItem>
                                    );
                                })
                            }
                    </Select> */}

                    <RoleSelect role={role} setRole={setRole} />

                    <TextField
                        margin="dense"
                        id="name"
                        label="Pfadiname"
                        type="text"
                        value={ name }
                        fullWidth
                        onChange={ (e) => setName(e.target.value) }
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Adresse"
                        type="email"
                        value={ email }
                        fullWidth
                        onChange={ (e) => setEmail(e.target.value) }
                    />
                    <TextField
                        margin="dense"
                        id="mobile"
                        label="Handynummer"
                        type="tel"
                        value={ mobile }
                        fullWidth
                        onChange={ (e) => setMobile(e.target.value) }
                    />

                    {/* <InputLabel id="demo-simple-select-label">Rolle</InputLabel> */}
                </DialogContent>
               
                <DialogActions>

                    
                    <Button onClick={onClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={onSave} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default AddUser;