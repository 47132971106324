import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './header.scss';
import Info from './Info';
import Logout from '../_helpers/logout';
import { UserContext } from '../UserContext';

const Header = ({title}) => {
    const {user, setUser} = useContext(UserContext);
    // const history = useHistory();

    const handleLogout = () => {
        Logout(setUser)
            .then((success) => {
                localStorage.removeItem('token');
                setUser(null);
            }, (error) => {

            });
    }

    return (
    <div>
        <div className="banner">
        <svg id="banner-svg" width="1912.655" height="103.752" viewBox="0 0 1912.655 103.752">
            {/* <defs>
                <linearGradient id="a" x1="0.134" y1="0.425" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#eeb800"/><stop offset="1" stop-color="#ffcb1c"/>
                </linearGradient>
                </defs> */}
                <path d="M-9.139,52.254s62.768-16.5,119.147,0S202.828,55,257.832,39.878s1645.684,0,1645.684,0V-44H-9.139Z" transform="translate(9.139 44.003)" fill={ `#${user.color}` } />
            </svg>

            <h1>{ (typeof(title) === 'undefined' ? 'Bier App' : title) }</h1>
            {/* <Link exact to="/login" id="logout"> */}
                <i className="logout-icon" onClick={ handleLogout }></i>
            {/* </Link> */}
        </div>
    </div>
);
}

export default Header;