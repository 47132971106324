const Liter = ({litres}) => {

    return(
        <div id="liter">
        <h2>{ litres !== null ? litres.toFixed(2) : '-' }</h2>
        <p>Liter insgesamt</p>
    </div>
    );
}

export default Liter;