import logo from './logo.svg';
import './App.css';

import { useJwt, isExpired } from 'react-jwt';

import React, { useContext, createContext, useState, useMemo } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard, Ranking, Statistics, Log, Settings, Login } from './pages';
import { Nav } from './layout';
import { PrivateRoute } from './_components';
import axios from 'axios';
import Drinkers from './pages/Drinkers';
import Info from './layout/Info';
import Finance from './pages/Finance';
import './pages/app.scss';
import { UserContext } from "./UserContext";
import renewToken from "./_helpers/renew-token";

function App() {

  const [infos, setInfos] = useState({});

  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  axios.defaults.headers.common['Authorization'] = 'Bearer: ' + localStorage.token;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.withCredentials = true;

  // Add a response interceptor
  axios.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    // if there are no messages to display
    if(typeof response.data.message === 'undefined') {
      return response;
    }

    // if there are
    setInfos(response.data.message);
    return response;

  }, async (error) => {

    const originalRequest = error.config;
    // if the request failed wird error-status 401: renew token and try again
    if (error.response.status === 401 && !originalRequest._retry) {
      
      originalRequest._retry = true;

      try {
        const new_token = await renewToken(value.setUser);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + new_token;
        originalRequest.headers.Authorization = 'Bearer: ' +  new_token;
        console.log(user);
        return axios(originalRequest);

      } catch(error) {
        // if the refresh-token is expired, an error will occur
        console.log(error);
        // TODO: fix this, need to change the user to redirect to the login page. Right now, when both tokens are expired and the user tries to do a request, the backup-auth-request will fail but the user is not logged out.
      }
    }


    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // if there is no response
    if(typeof error.response === 'undefined') {
      return Promise.reject(error);
    }

    // if there are no messages to display
    if(typeof error.response.data.message === 'undefined') {
      return Promise.reject(error);
    }

    // if there are:
    setInfos(error.response.data.message);
    return Promise.reject(error);
  });

  return (
    <div>
      <div className="info-container">
        
      {
        Object.keys(infos).map(type => {
          return(
          Object.keys(infos[type]).map(message => {
            // console.log(infos[type][message]);
            return(
              <Info severity= { type } key={ new Date() }>
                { infos[type][message] }
              </Info>
            );
          })
          )
        })
      }
      </div>
      
    <Router>
      <Switch>
        <UserContext.Provider value={value}>
          <PrivateRoute  exact path='/'>
            <Dashboard />
          </PrivateRoute>

          <PrivateRoute  path='/ranking'>
            <Ranking />
          </PrivateRoute>
          <PrivateRoute  path='/statistics'>
            <Statistics />
          </PrivateRoute>
          <PrivateRoute  path='/log'>
            <Log />
          </PrivateRoute>
          <PrivateRoute  path='/settings'>
            <Settings />
          </PrivateRoute>
          <PrivateRoute  path='/drinkers'>
            <Drinkers />
          </PrivateRoute>
          <PrivateRoute path='/finance'>
            <Finance />
          </PrivateRoute>

          <Route path='/login'>
            <Login />
          </Route>
        </UserContext.Provider>
      </Switch>
    </Router>
    
    </div>
  );
}

export default App;
