import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../UserContext';


const Logout = async (setUser) => {
    console.log('in theroy, you should get logged out now');
    return new Promise((resolve, reject) => {
        axios
            .get('/api/logout')
            .then((response) => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            })
    })
    // const {user, setUser} = useContext(UserContext);
    
    // setUser(null);
    // localStorage.setItem('token', '');
    // // document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // axios.get('/api/logout').then((response) => {
    //     console.log(response)
    // }).catch((error) => { console.log(error)});
}

export default Logout;