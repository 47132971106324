import React, { useEffect, useState, componentDidUpdate, useContext } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { decodeToken } from 'react-jwt';

import Layout from '../layout';
import Switch from '@material-ui/core/Switch';
import './settings.scss';
import axios from 'axios';
import { stringify } from 'qs';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserContext';
import renewToken from '../_helpers/renew-token';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PeopleOutlineIcon from '@material-ui/icons/People';
import AttachMoneyOutlineIcon from '@material-ui/icons/AttachMoney';
import LocalBarOutlinedIcon from '@material-ui/icons/LocalBarOutlined';
import CachedIcon from '@material-ui/icons/Cached';

function Settings() {

  const token = decodeToken(localStorage.token);

  const {user, setUser} = useContext(UserContext);

  const [ranking, updateRanking] = useState(false);
  const [spesen, updateSpesen] = useState(false);
  const [party, updateParty] = useState(0);
  const [mobile, updateMobile] = useState(false);
  const [email, updateEmail] = useState(false);
  const [rankingLoaded, updateRankingLoaded] = useState(false);
  const [spesenLoaded, updateSpesenLoaded] = useState(false);
  const [parties, updateParties] = useState([]);

  const roleNames = [
    '',
    'Beobachter',
    'Trinker',
    'Barkeeper',
    'Admin',
    'Entwickler'
  ]

  useEffect(() => {

    // getSettings
    axios
    .get('/api/user/getSettings')
    .then((response) => {
      if(response.data.show_in_ranking === '1') {
        updateRanking(true);
      }
      if(response.data.allow_foreign_entries === '1') {
        updateSpesen(true);
      }

      updateEmail(response.data.email);
      updateMobile(response.data.mobile);

      updateRankingLoaded(true);
      updateSpesenLoaded(true);

      updateParty(response.data.default_party);
    })
    .catch();

    // getParties
    axios
    .get('/api/user/getUserParties')
    .then((response) => {
      updateParties(response.data);
    })
    .catch((error) => {

    })

  }, []);

  // change the settings with the new settings in the newSettings-object
  const changeSettings = (newSettings) => {
    axios
    .post('/api/user/setSettings', stringify({
      show_in_ranking: (newSettings.ranking ? 1 : 0),
      allow_foreign_entries: (newSettings.spesen ? 1 : 0),
      default_party: newSettings.default_party,
      mobile: mobile,
      email: email,
    }))
    .then((response) => {
      renewToken(setUser)
        .then((token) => {
          setUser(decodeToken(token));
          localStorage.setItem('token', token);
        }, (error) => {})
      return true;
    })
    .catch((error) => {
      return false;
    });
  }

  return(
    <Layout title="Einstellungen">
      <div id="settings-page">
        <div id="allgemein">

      <p>Allgemein</p>

      <div className="settings-element" id="change-ranking">
          <i className="icon"></i>
          <p>Mich in der Rangliste anzeigen</p>
          <Switch 
            color="primary"
            checked={ ranking }
            disabled={ ! rankingLoaded }
            onChange={ (e) => { 
              updateRanking(e.target.checked);

              changeSettings({
                ranking: e.target.checked,
                spesen: spesen,
                default_party: party
              });
            } } 
          />
      </div>
      <div className="settings-element" id="change-spesen">
        <i className="icon"></i>
        <p>Spendieren erlauben</p>
          <Switch
            color="primary"
            checked={ spesen }
            disabled={ ! spesenLoaded }
            onChange={ (e) => { 
              updateSpesen(e.target.checked);

              changeSettings({
                ranking: ranking,
                spesen: e.target.checked,
                default_party: party
              });
            } }
          />
      </div>
      <div className="settings-element" id="change-party">
        <i className="icon"></i>
        <p>Trinkgelage</p>
        <FormControl className="select-party">
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="capitalize"
          value={ party }
          onChange={ (e) => {
            updateParty(e.target.value);

            changeSettings({
              ranking: ranking,
              spesen: spesen,
              default_party: e.target.value
            });
          } }
        >
          {
            parties.map((party, index) => {
              return (
                <MenuItem value={ party.id } className="capitalize" key={ index }>{ party.name }</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      </div>
          {/* TODO: add change-password, email and mobile */}
    </div>

      <div id="informationen">
        <p>Informationen</p>

        <div className="settings-element" id="role-info">
          <CachedIcon />
          <p>Rolle</p>
          <p>{ roleNames[token.role - 1] }</p>
        </div>
      </div>
      
      {/* only show admin-settings if user us admin */}
      { parseInt(user.role) > 4 ? (
        <div id="admin">
        <p>Admin</p>
          <div className="settings-element" id="trinker">
            <PeopleOutlineIcon />
            <p>Biertrinkende</p>
            <Link to="/drinkers">
              <ArrowForwardIosIcon />
            </Link>
          </div>
          <div className="settings-element" id="finance">
            <AttachMoneyOutlineIcon />
            <p>Finanzen</p>
            <Link to="/finance">
              <ArrowForwardIosIcon />
            </Link>
          </div>
        <div className="settings-element" id="drinks">
          <LocalBarOutlinedIcon />
          <p>Getränke</p>
        </div>
      </div>
      ) : ''
    }

    {/* only show developer-settings if user is developer */}
    { parseInt(user.role) > 5 ? (
      <div id="developer">
        <p>Entwickler</p>
          <div className="settings-element" id="party">
            <p>Trinkgelage</p>
          </div>
      </div>
    ) : '' }

      <p id="info">
        Mit vill 🍺 und ❤️ gmacht vom Strolch<br />
        Froge, Bugs und Spende: strolch@pfadimoerschwil.ch
      </p>
      </div>
      

    </Layout>
  );
}

export default Settings;