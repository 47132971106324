import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { stringify } from 'qs';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EditUser = ({open, editedUser, onClose}) => {

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [role, setRole] = useState();

    const roleNames = [
        'Archiviert',
        'Beobachter',
        'Trinker',
        'Barkeeper',
        'Admin',
        'Entwickler'
      ]

    // when editedUser is changed
    useEffect(() => {
        setId(editedUser.id);
        setName(editedUser.name);
        setEmail(editedUser.email);
        setMobile(editedUser.mobile);
        setRole(editedUser.role);
    }, [editedUser]);

    const onSave = () => {
        axios.post('/api/user/setUserAdminSettings', stringify({
            user_id: id,
            name: name,
            email: email,
            mobile: mobile,
            role: role,
        }))
        .then((response) => {
            onClose();
        })
        .catch((error) => {

        })
    }

    return(
        <Dialog
                open={open} 
                onClose={onClose} 
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{ editedUser.name }</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Benutzereinstellungen
                </DialogContentText>
                <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ role }
                onChange={ (e) => setRole(e.target.value) }
                >
                    {
                        roleNames.map((roleName, index) => {
                            return(
                                <MenuItem value={ index + 1 } key={ index }>{ roleName }</MenuItem>
                            );
                        })
                    }
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
                <TextField
                    margin="dense"
                    id="name"
                    label="Pfadiname"
                    type="text"
                    value={ name }
                    fullWidth
                    onChange={ (e) => setName(e.target.value) }
                />
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Adresse"
                    type="email"
                    value={ email }
                    fullWidth
                    onChange={ (e) => setEmail(e.target.value) }
                />
                <TextField
                    margin="dense"
                    id="mobile"
                    label="Handynummer"
                    type="tel"
                    value={ mobile }
                    fullWidth
                    onChange={ (e) => setMobile(e.target.value) }
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={onSave} color="primary">
                    Speichern
                </Button>
                </DialogActions>
            </Dialog>
    )
}

export default EditUser;