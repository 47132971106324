import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './login.scss';
import axios from 'axios';
// import Cookies from 'js-cookie';

import { stringify } from 'qs';
// import { useCookies } from 'react-cookie';
import { UserContext } from '../UserContext';
import { useJwt, isExpired, decodeToken } from 'react-jwt';
import renewToken from '../_helpers/renew-token';


function Login( {setToken} ) {

    const { user, setUser } = useContext(UserContext);

    let history = useHistory();
    let location = useLocation();
  
    let { from } = location.state || { from: { pathname: "/" } };

    useEffect(() => {
        renewToken().then((token) => {
            console.log(token);
            setUser(decodeToken(token));

                localStorage.setItem('token', token);
                history.replace(from);

        }, (error) => {
            console.log(error);
        })
    }, []);

    function checkLogin() {
        axios
            .post('/api/auth', stringify({
                username: name,
                password: password
            }))
            .then(function(response) {
                console.log(response.data);

                setUser(decodeToken(response.data.token));

                // setCookie('refresh_token', response.data.);
                // Cookies.set('refresh_token', response.data.refresh_token, { expires: 7, HttpOnly: false });

                localStorage.setItem('token', response.data.token);
                // setToken(response.data.token);

                // when login was successful: redirect
                history.replace(from);

            })
            .catch(function(error) {
                console.log(error);
            });
    };

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    
    function handleSubmit(event) {
        event.preventDefault();
        checkLogin();
      }

    return (
        <div className="login">
            {/* <h1>Ihträge.ch</h1> */}
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        id="name"
                        placeholder="Benutzername"
                         />
                </label>
                <label htmlFor="password">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        placeholder="Passwort"
                        />
                </label>
                <input type="submit" value="Login" />
            </form>
            <div className="footer">
                <i className="logo"></i>
                <h3>ihträge.ch</h3>
            </div>
            
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }

// const Login = () => (
//     <div className="login">
//         <form className="" noValidate autoComplete="off">
//             <TextField id="standard-basic" label="Standard" />
//             <TextField id="filled-basic" label="Filled" variant="filled" />
//             <TextField id="outlined-basic" label="Outlined" variant="outlined" />
//         </form>
//     </div>

// );

export default Login;