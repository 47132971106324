import React, { Component } from 'react';
import { decodeToken } from 'react-jwt';

const DashboardTransaction = ({transaction}) => {

    const token = decodeToken(localStorage.token);

    console.log(transaction);
    var timestamp_formatted = transaction.timestamp.replaceAll(' ', 'T') + "+01:00";
    var time = new Date(timestamp_formatted);
    time.setHours(time.getHours());
    var day = ("0" + time.getDate()).slice(-2);
    var month = ("0" + (time.getMonth() + 1)).slice(-2);
    var year = time.getFullYear().toString().slice(-2);
    var hour = ("0" + time.getHours()).slice(-2);
    var minute = ("0" + time.getMinutes()).slice(-2);
    var date_time = day + '.' + month + '.' + year + ' ' + hour + ':' + minute;

    var classes = `activity ${transaction.new ? 'new' : ''} ${transaction.drinking_user === token.sub ? '' : 'ghost'}`

    if(parseInt(transaction.transaction_type) === 1) {
        return(
            <div key={ transaction.id } className={ classes } >
                <p className="name">{ transaction.drink_name }</p>
                <p className={`drinker-name ${transaction.drinking_user === token.sub ? 'hide' : ''}`}>{ transaction.drinking_user === token.sub ? '' : `für ${transaction.drinking_user_name}` }</p>
                <p className="amount">CHF { transaction.drink_selling_price }</p>
                <p className="volume">{ parseInt(transaction.drink_volume * 10).toFixed(1) } dl</p>
                <p className="time"> {date_time}, <span className="capitalize">{ transaction.paying_user_name }</span></p>
                {/* <p className="payer-name">gezahlt von { Capitalize(transaction.paying_user_name )}</p> */}
                <img src={ `${process.env.REACT_APP_API_URL}/uploads/${transaction.drink_image_file_name}` } alt="" />
            </div>
        );
    } else {
        classes = 'activity payment';

        return(
            <div key={ transaction.id } className={ classes } >
                <p className="name">Einzahlung</p>
                <p className="amount">CHF { transaction.payment_amount }</p>
                <p className="time"> {date_time}, <span className="capitalize">{ transaction.paying_user_name }</span></p>
                {/* <p className="payer-name">gezahlt von { Capitalize(transaction.paying_user_name )}</p> */}
                <img src={ `${process.env.REACT_APP_API_URL}/uploads/${transaction.drink_image_file_name}` } alt="" />
            </div>
        );
    }
}

export default DashboardTransaction;