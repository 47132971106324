import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import TimeAgo from 'react-timeago'
import germanStrings from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { decodeToken } from 'react-jwt';

import './transaction.scss';

const Transaction = ({ data }) => {

    const token = decodeToken(localStorage.token);

    const colorClass = (data.transaction_type === "1") ? "negative" : "positive";
    const formatter = buildFormatter(germanStrings)

    var time = new Date(data.timestamp)
    time.setHours(time.getHours());

    const weekdays = ['Sunntig', 'Mentig', 'Zistig', 'Mittwoch', 'Donstig', 'Fritig', 'Samstig'];

    const dayName = weekdays[time.getDay()];
    const day = ("0" + time.getDate()).slice(-2);
    const month = ("0" + (time.getMonth() + 1)).slice(-2);
    const year = time.getFullYear().toString().slice(-2);
    const hour = ("0" + time.getHours()).slice(-2);
    const minute = ("0" + time.getMinutes()).slice(-2);
    // var date_time = dayName day + '.' + month + '.' + year + ' ' + hour + ':' + minute;
    const date_time = `${dayName}, ${day}.${month}.${year} ${hour}:${minute}`;

    // if the transactions was a consumption
  if(data.transaction_type === "1") {
      return (
      <Accordion className="transaction">
      <AccordionSummary
      //   expandIcon={}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="summary"
      >
        <div>
        <p className="name">
          {data.drink_name}
        </p>
          <p className={ `balance ${colorClass} ${data.paying_user === token.sub ? '' : 'hide'}` }>
              CHF -{data.drink_selling_price}
          </p>
          <p className={ `balance volume ${data.drinking_user === token.sub ? '' : 'hide'}` }>
              +{data.drink_volume}l
          </p>
        <p className="time">
          <TimeAgo date={ time } formatter={formatter} />
        </p>
        </div>
      </AccordionSummary>
      <AccordionDetails className="details">
        <p className="pay">Gezahlt von <span>{ data.paying_user_name }</span> und getrunken von <span>{ data.drinking_user_name }</span></p>
        <p className="date_time">{ date_time }</p>
      </AccordionDetails>
    </Accordion>
    )
     } else {
      //  if the transaction was a payment
      return (
        <Accordion className="transaction">
        <AccordionSummary
        //   expandIcon={}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="summary"
        >
          <div>
          <p className="name">
            Einzahlung
          </p>
            <p className={ `balance ${colorClass}` }>
                CHF +{data.payment_amount}
            </p>
          <p className="time">
            <TimeAgo date={ time } formatter={formatter} />
          </p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="details">
          <p className="pay">Eingezahlt von <span>{ data.paying_user_name }</span></p>
          <p className="date_time">{ date_time }</p>
        </AccordionDetails>
      </Accordion>
      )
  }
  
}


export default Transaction;