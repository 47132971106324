import axios from "axios";
import Logout from './logout';

const renewToken = async (setUser) => {
    return new Promise((resolve, reject) => {
        // try to get a new token with the refresh-token
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/auth`)
            .then((response) => {
                // if we get here, we received a new token
                resolve(response.data.token);
            })
            .catch((error) => {
                // if the refresh-token is expired:
                // Logout(setUser);
                console.log('refresh token exxpired');
                reject(error);
            });
    });
}


export default renewToken;