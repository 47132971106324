import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import './quicksearch.scss';
import { stringify } from 'qs';
import RoleSelect from './RoleSelect.js';


const Quicksearch = ({onClose}) => {

    const [query, setQuery] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        id: false,
        name: false,
    });
    const [role, setRole] = useState(3);

    const clearSelect = () => {
        setSelectedUser({
            id: false,
            name: false,
        });
    }


    // on ever input change
    useEffect(() => {
        // if the input field is blank, don't search
        if(query === "") {
            setUsers([]);
            clearSelect();
            return false;
        }

        const timeoutId = setTimeout(() => {

            // search the user
            axios
            .get(`/api/user/searchUser?query=${query}`)
            .then((response) => {
                // console.log(response.data);
                setUsers(response.data.users);
                setSelectedUser(response.data.users[0]);
            })
            .catch((error) => {});

        }, 500);
        return () => clearTimeout(timeoutId);
    }, [query]);


    const handleSelect = (e) => {
        // console.log(e.target);
        setSelectedUser({
            id: e.target.attributes.user.value,
            name: e.target.textContent,
        });
    }

    const addExistingUser = () => {

        // if a user is selected
        if(selectedUser.name) {
            console.log("added", selectedUser.name);

            axios
            .post('/api/user/addUserToParty', stringify({
                user: selectedUser.id,
                role: role,
            }))
            .then((response) => {
                setQuery("");
                clearSelect();
                onClose();
            })
            .catch((error) => {});
        }
    }

    return (
        <div>
            <RoleSelect role={role} setRole={setRole} />
            <div className="search-user">
                <InputBase
                    id="search-user-input"
                    value={query}
                    onChange={ (e) => { setQuery(e.target.value) } }
                    placeholder="Trinker suchen"
                    inputProps={{ 'aria-label': 'Trinker suchen' }}
                />
                {/* <Divider  orientation="vertical" /> */}
                <IconButton
                    type="submit"
                    aria-label="search"
                    id="search-user-icon"
                    onClick={ addExistingUser }
                >
                    +
                </IconButton>
                <List id="results">
                    {
                        users.map((val, index) => {
                            // console.log(val.name);
                            return (
                                <ListItem
                                    key={ index }
                                    user={ val.id }
                                    onClick={ handleSelect }
                                    className={ (val.id === selectedUser.id) ? "selected" : "" }
                                >
                                    {/* <ListItemText>{ val.name }</ListItemText> */}
                                    { val.name }
                                </ListItem>
                            );
                        })
                    }
                </List>
            </div>
        </div>
    );
}

export default Quicksearch;