import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../_services';
import { UserContext } from '../UserContext';

import { useJwt } from 'react-jwt';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ token, children, ...rest }) {

    // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJhdWQiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJleHAiOjE2MTM1MDM1MDYsInN1YiI6IjYiLCJuYW1lIjoidml2YSIsInJvbGUiOiIzIiwiY3VycmVudF9wYXJ0eSI6IjEiLCJyYW5raW5nIjoiMSJ9.JYJz8oPBgzkOAyFKYzqvUFa8T3I4E-zdxXd-mJ5vHis';
    // const token = localStorage.token;
    // const { decodedToken, isExpired } = useJwt(token);
    // console.log('token: ', decodedToken,'expired: ', isExpired);

    const {user, setUser} = useContext(UserContext);
  
    return (
      <Route
        {...rest}
        render={({ location }) => 
            // authenticationService.currentUserValue ? (
          user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  export default PrivateRoute;