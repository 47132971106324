import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const RoleSelect = ({role, setRole}) => {
    
    // const [role, setRole] = useState(3);

    const roleNames = [
        'Archiviert',
        'Beobachter',
        'Trinker',
        'Barkeeper',
        'Admin',
        'Entwickler'
    ]

    return (
        <div>
            <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={ role }
                onChange={ (e) => setRole(e.target.value) }
                >
                    {
                        roleNames.map((roleName, index) => {
                            return(
                                <MenuItem
                                    value={ index + 1 }
                                    key={ index }
                                >
                                    { roleName }
                                </MenuItem>
                            );
                        })
                    }
            </Select>
        </div>
    );
}

export default RoleSelect;