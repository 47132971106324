import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { stringify } from 'qs';

import Layout from '../layout';
import './log.scss';
import Transaction from './log/Transaction';

const Log = () => {

  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // getTransactionsFromUserParty
    axios
    .post('/api/user/getTransactionsFromUserParty')
    .then(function(response) {
        // console.log(response.data);
        setTransactions(response.data);
    })
    .catch(function(error) {
        // updateRank('-');
    });
  }, [])

  const TransactionScrollListener = (e) => {
    const down = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // console.log(e.target.scrollHeight-e.target.scrollTop, e.target.clientHeight);
    // console.log(down);
  
    if(down) {

      const offset = e.target.children.length;

      axios
      .post('/api/user/getTransactionsFromUserParty', stringify({offset: offset}))
      .then(function(response) {
          console.log(response.data);
          setTransactions(transactions.concat(response.data));
      })
      .catch(function(error) {
          setTransactions([])
      });
    }

    
  }

  return (
  <Layout title="Logbuch">
    <div id="log-top">
      <p>Letzte Transaktionen</p>
    </div>
      <div id="log-container" onScroll={ TransactionScrollListener }>
          { 
        transactions.map((transaction, index) => {
          // console.log(transaction);
        
          return(
            <Transaction data={ transaction } key={ index } />
          )
        })
      }
      { transactions.length === 0 ? (
        <p>Noch keine Aktivität...</p>
      ) : '' }
      
    </div>
    
  </Layout>);
}
  

export default Log;