import React, { Component } from 'react';


// import '../slider.scss';
import './MySlider.scss';


// const slider = React.createRef();
// const container = React.createRef();
// const isTouchDevice = 'ontouchstart' in document.documentElement;

export default class SwipeableButton extends Component {
  
  state = {}

    slider = React.createRef();
    container = React.createRef();
    isTouchDevice = 'ontouchstart' in document.documentElement;

  componentDidMount() {
    if(this.isTouchDevice) {
      document.addEventListener('touchmove', this.onDrag);
      document.addEventListener('touchend', this.stopDrag);
    } else {
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);  
    }
    this.containerHeight = this.container.current.clientHeight - 50;
    // console.log(this.containerHeight);
  }

  onDrag =e=> {
    if(this.unmounted || this.state.unlocked) return;
    if(this.isDragging) {
      if(this.isTouchDevice) {
        this.sliderBottom = Math.min(Math.max(0, this.startY - e.touches[0].clientY ), this.containerHeight);
      } else {
        this.sliderBottom = Math.min(Math.max(0, this.startY - e.clientY), this.containerHeight);
        // this.sliderBottom = Math.min(Math.max(0, e.clientY - this.startY), this.containerHeight);
      }
      this.updateSliderStyle();
    }
  }

  updateSliderStyle =()=> {
    if(this.unmounted || this.state.unlocked) return;
    this.slider.current.style.bottom = (this.sliderBottom + 50)+'px';
  }

  stopDrag =()=> {
    if(this.unmounted || this.state.unlocked) return;
    if(this.isDragging) {
      this.isDragging = false;
      if(this.sliderBottom > this.containerHeight * 0.9) {
        this.sliderBottom = this.containerHeight;
        if(this.props.onSuccess) {
          this.props.onSuccess();
          this.onSuccess();
        }
      } else {
        this.sliderBottom = 0;
        if(this.props.onFailure) {
          this.props.onFailure();
        }
      }
      this.updateSliderStyle();
    }
  }

  startDrag =e=> {
    if(this.unmounted || this.state.unlocked) return;
    this.isDragging = true;
    if(this.isTouchDevice) {
      this.startY = e.touches[0].clientY;
    } else {
      this.startY = e.clientY;
    }
  }

  onSuccess =()=> {
      this.sliderBottom = 0;
      this.updateSliderStyle();
    // container.current.style.height = '50px';
    // container.current.style.height = container.current.clientHeight+'px';
    // this.setState({
    //   unlocked: true
    // })
  }

  getText =()=> {
    return this.state.unlocked ? (this.props.text_unlocked || 'UNLOCKED') : (this.props.text || 'SLIDE')
  }

  reset =()=> {
    if(this.unmounted) return;
    this.setState({unlocked: false}, ()=> {
      this.sliderBottom = 0;
      this.updateSliderStyle();
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() { 
    return (
      <div className='ReactSwipeButton'>
        <div className={'rsbContainer ' + (this.state.unlocked ? 'rsbContainerUnlocked' : '')} ref={this.container}>
          <div className='rsbcSlider' 
            ref={this.slider} 
            onMouseDown={this.startDrag} 
            style={{background: this.props.color}}
            onTouchStart={this.startDrag}>
            {/* <span className='rsbcSliderText'>{this.getText()}</span> */}
            <span className='rsbcSliderArrow'></span>
            <span className='rsbcSliderCircle' style={{background: this.props.color}}></span>
          </div>
          {/* <div className='rsbcText'>{this.getText()}</div> */}
        </div>
      </div>
    )
  }
}