import React, { useState } from "react";

const Rang = ({rank}) => {

    const [className, setClassName] = useState('');

    var classes = '';

    // if the user is in the top 3
    if([1, 2, 3].includes(rank)) {
        classes = ['first-rank', 'second-rank', 'third-rank'][rank - 1];
    }

    return(
    <div id="rang">
        <h2 className={ classes }>{ rank }.</h2>
        <p>Rang</p>
    </div>
    );
}

export default Rang;