import React from 'react';

import Nav from './Nav';
import Header from './Header';
// import Footer from './Footer';
import './content.scss';
import './common.scss';

const Layout = (props) => (
  <div className='content'>
    <Header title={ props.title } />
    <div className="banner-placeholder"></div>
    {props.children}
    <Nav />
  </div>
);

export default Layout;
// export { Layout, Nav };
// export { Layout, Nav, Footer };