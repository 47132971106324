import React, { useEffect, useState } from 'react';

import Layout from '../layout';
import axios from 'axios';
import './ranking.scss';

function Ranking() {

  const [ranking, updateRanking] = useState([]);

  useEffect(() => {

    // get ranking
    axios
    .get('api/user/getRanking')
    .then((response) => {
      updateRanking(response.data);
    })
    .catch((error) => {

    });

  }, []);

  return(
    <Layout title="Rangliste">
      <div id="ranking-top">
        <p>All-Time Ranking</p>
      </div>
      <div id="ranking-container">
          <div id="elements">
            {
              ranking.map((user, rank) => {

                return(
                  <div class="element" key={ rank }>
                    <div class="rank">{ rank + 1 }</div>
                    <div class="name">{ user.name }</div>
                    <div class="volume">{ user.volume }</div>
                  </div>
                );
              })
            }
          </div>
      </div>

      
    </Layout>
  );
}

export default Ranking;