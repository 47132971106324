import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import Layout from '../layout';
import './statistics.scss';

const Statistics = () => {

  const [consumedDrinks, setConsumedDrinks] = useState([]);

  useEffect(() => {

    axios
      .get('api/user/getConsumedDrinks')
      .then((response) => {
        console.log(response.data);
        setConsumedDrinks(response.data);
      })
      .catch((error) => {

      });

  }, []);

  return (
      <Layout title="Statistik">
        <div className="statistics">
          {/* <p>Chill mol, die Siite isch nonig ganz fertig...</p> */}
          <div id="doughnut-container">
            <Doughnut
              data={
                {
                  datasets: [{
                    data: consumedDrinks.map((drink) => drink.count),
                    backgroundColor: consumedDrinks.map((drink) => drink.statistics_color),
                  }],
                  labels: consumedDrinks.map((drink) => drink.name)
                }
              }
            />
          </div>
          
        </div>
      </Layout>
    );
  }
export default Statistics;