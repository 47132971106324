import './debt.scss';

const Debt = ({id, debt}) => {
    return (
        <div id={id}>
            <h2>CHF { Math.abs(debt).toFixed(2) }</h2>
            <p>
                {
                    debt < 0 ? 'Debitoren (Schulden total)' : 'Kreditoren (Guthaben total)'
                }
            </p>
        </div>
    );
}

export default Debt;