import axios from "axios";
import { useEffect, useState } from "react";
import Layout from "../layout";
import './finance.scss';
import Debt from './finance/Debt';
import AddDeposit from './finance/AddDeposit';
import { stringify } from 'qs';
import Fab from '@material-ui/core/Fab';

const Finance = () => {

    const [bilanz, setBilanz] = useState('0.00');
    const [addDepositOpen, setAddDepositOpen] = useState(false);

    useEffect(() => {
        fetchTotalBilanz();
    }, [])

    const fetchTotalBilanz = () => {
        axios
        .get('/api/user/getTotalBalanceFromParty')
        .then((response) => {
            // console.log(response.data);

            setBilanz(parseFloat(response.data.bilanz_total));
        })
        .catch((error) => {
            setBilanz('-');
        });
    }

    return (
        <Layout title="Finanzen">
            <div id="finance">
                <Debt id="debt" debt={ bilanz } />
                <div className="fabs">
                    <Fab
                        color="primary"
                        variant="extended"
                        size="small"
                        onClick={ () => setAddDepositOpen(true) }
                    >
                        Einzahlung
                    </Fab>
                </div>
                
                <AddDeposit
                    open={ addDepositOpen }
                    onClose={ () => {
                            setAddDepositOpen(false);
                            fetchTotalBilanz();
                        }
                    } 
                />
            </div>
        </Layout>
    );
}

export default Finance;