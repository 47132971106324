const Guthaben = ({balance}) => {

    var classes = (balance > 0) ? 'positive' : 'negative';
    classes = (balance === 0) ? '' : classes;

    return(
        <div className="guthaben">
            <h2 className={ classes }>{ balance !== null ? balance.toFixed(2) : '-' }</h2>
            <p>Franken Guthaben</p>
        </div>
    );
}

export default Guthaben;