import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import { UserContext } from "../UserContext";

const navs = [
  { path: '/', name: 'dashboard' },
  { path: '/ranking', name: 'ranking' },
  { path: '/statistics', name: 'statistics' },
  { path: '/log', name: 'log' },
  { path: '/settings', name: 'settings' },
];


const Nav = () => {
  
  const {user} = useContext(UserContext);

  return(
    <nav>
      <ul>
        {navs.filter((navItem) => {
          if(navItem.name === "ranking" && user.ranking !== "1") {
            return false;
          }
          return true;
        })
        .map((navItem) => {
          
          return (
            <li className="menu-item" key={navItem.path}>
                <div>
                    <NavLink exact to={navItem.path} activeClassName='menu-selected'>
                        <i id={navItem.name}></i>
                    </NavLink>
                </div>
            </li>
          );
          
        })}
      </ul>
    </nav>
  );
}

export default Nav;