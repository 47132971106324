import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import { stringify } from 'qs';

const AddDeposit = ({open, onClose}) => {

    const [payingUser, setPayingUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [payingMethod, setPayingMethod] = useState(0);
    const [amount, setAmount] = useState(0.00);

    useEffect(() => {
        console.log(users);

        axios
        .get('/api/user/getUsersFromParty')
        .then((response) => {
            console.log(response.data);
            setUsers(response.data);
            // objs.sort((a,b) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
        })
        .catch((error) => {

        });

    }, []);

    const onSave = () => {
        axios
        .post('/api/user/newDeposit', stringify({
            'paying_user': payingUser ? payingUser : null,
            'amount': amount,
        }))
        .then((response) => {
            onClose();
        })
        .catch((error) => {

        });
    }

    const reset = () => {
        setAmount(0.00);
        setPayingMethod(0);
        setPayingUser(false);
    }

    return (
        <Dialog
            open={open} 
            onClose={() => {
                reset();
                onClose()
            }} 
            // TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">Neue Einzahlung</DialogTitle>
            
            <DialogContent>
                <TextField
                    label="Betrag"
                    id="standard-start-adornment"
                    // className={clsx(classes.margin, classes.textField)}
                    required={true}
                    type="number"
                    inputProps={
                        {
                            step: 'any',
                            min: '0',
                        }
                    }
                    InputProps={{
                        startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                    }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </DialogContent>

            <DialogContent>
                {/* <DialogContentText>
                        Neuer Einzahlung erstellen
                    </DialogContentText> */}
                <FormControl>
                    <InputLabel id="paying-user-label">Trinker</InputLabel>
                    <Select
                        labelId="paying-user-labe"
                        id="demo-simple-select"
                        value={ payingUser }
                        onChange={ (e) => setPayingUser(e.target.value) }
                    >
                        {/* <MenuItem value={false}>Kein Benutzer</MenuItem> */}
                        {
                            users
                            .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                            .map((user, index) => {
                                return(
                                    <MenuItem value={user.id} key={index}>{user.name}</MenuItem>
                                )
                            })
                        }
                        {/* <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                </FormControl>
                </DialogContent>
                <DialogContent>
                {/* <FormControl>
                    <InputLabel id="paying-method-id">Zahlungsart</InputLabel>
                    <Select
                        labelId="paying-method-id"
                        value={ payingMethod }
                        onChange={ (e) => setPayingMethod(e.target.value) }
                    >
                        TODO: make dynamic
                        <MenuItem value={0}>Twint</MenuItem>
                        <MenuItem value={1}>Barzahlung</MenuItem>
                    </Select>
                </FormControl> */}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                            reset();
                            onClose()
                        }
                    }
                color="secondary">
                    Abbrechen
                </Button>
                <Button onClick={ onSave } color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddDeposit;