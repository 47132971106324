import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useState } from 'react';
import './info.scss';

const Info = (props) => {

    const [open, setOpen] = useState(true);
    
    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
        >
            <Alert severity={ props.severity } onClose={handleClose}>
            { props.children }
            </Alert>
        </Snackbar>
    );
}

export default Info;