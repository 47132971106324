/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

import Layout from '../layout';

import './dashboard.scss';
import './slider.scss';

// import Slider from '@material-ui/core/Slider';
import MySlider from './dashboard/MySlider';
import DashboardTransaction from './dashboard/DashboardTransaction';

import axios from 'axios';

import { parse, stringify } from 'qs';

import { decodeToken } from 'react-jwt';
// import { CSSTransition } from 'react-transition-group' // ES6
import Rang from './dashboard/Rang';
import Liter from './dashboard/Liter';
import Guthaben from './dashboard/Guthaben';
import { UserContext } from '../UserContext';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


function Dashboard() {

    const { user } = useContext(UserContext);

    const [totalVolumen, updateTotalVolumen] = useState(null);
    const [rank, updateRank] = useState(0);
    const [drinks, updateDrinks] = useState([]);
    const [users, updateUsers] = useState([]);
    const [activities, updateActivities] = useState([]);
    const [drinkingUser, updateDrinkingUser] = useState(user.sub);
    const [spesen, updateSpesen] = useState(true);
    const [balance, updateBalance] = useState(null);
    const [spesenDisabled, updateSpesenDisabled] = useState(true);

   useEffect(() => {

        // getTotalVolume
        axios
            .get('/api/user/getTotalVolume')
            .then(function(response) {
                // console.log(response.data.volume);
                updateTotalVolumen(parseFloat(response.data.volume));
            })
            .catch(function(error) {
                updateTotalVolumen('-');
            });

        // getRank
        // TODO: update rank after consumption
        axios
            .get('/api/user/getRank')
            .then(function(response) {
                // console.log(response.data.rank);
                updateRank(response.data.rank);
            })
            .catch(function(error) {
                updateRank('-');
            });

        // getBalance
        axios
            .get('/api/user/getBalanceFromUserParty')
            .then( (response) => {
                const balance = parseFloat(response.data.initial_balance) + parseFloat(response.data.aktiven) - parseFloat(response.data.passiven);
                updateBalance(balance);
            })
            .catch( (error) => {
                updateBalance('-');
            });

        // getDrinks
        axios
            .get('/api/user/getDrinksFromParty')
            .then(function(response) {
                // console.log(response.data);
                updateDrinks(response.data);
            })
            .catch(function(error) {
                // updateRank('-');
            });

        // getAllActiveSpesenbareUsers
        axios
            .get('/api/user/getAllActiveSpesenbareUsers')
            .then(function(response) {
                // console.log(response.data);
                updateUsers(response.data);
            })
            .catch(function(error) {
                // updateRank('-');
            });

        // getTransactionsFromUserParty
        axios
            .post('/api/user/getTransactionsFromUserParty')
            .then(function(response) {
                // console.log(response.data);
                updateActivities(response.data);
            })
            .catch(function(error) {
                // updateRank('-');
            });
   }, []);

    const TransactionscrollListener = (e) => {
        const right = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;
        if (right) {

            const offset = e.target.children.length;

            // TODO: load older activities

            // getTransactionsFromUserParty
            axios
            .post('/api/user/getTransactionsFromUserParty', stringify({offset: offset}))
            .then(function(response) {
                // console.log(response.data);
                updateActivities(activities.concat(response.data));
            })
            .catch(function(error) {
                // updateRank('-');
            });
        }
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const Consume = (id) => {

        // make ajax call

        axios.post('/api/user/setConsumption', stringify({
            drink: id,
            drinking_user: drinkingUser,
            spesen: spesen
        }))
        .then((response) => {

                updateActivities([
                    {
                        drink_name: response.data.transaction.name,
                        drink_image_file_name: response.data.transaction.image_file_name,
                        paying_user_name: response.data.transaction.paying_user_name,
                        timestamp: response.data.transaction.timestamp,
                        drink_volume: response.data.transaction.volume,
                        drink_selling_price: response.data.transaction.selling_price,
                        id: response.data.transaction.id,
                        new: true,
                        drinking_user: response.data.transaction.drinking_user,
                        drinking_user_name: response.data.transaction.drinking_user_name,
                        paying_user: response.data.transaction.paying_user,
                        transaction_type: response.data.transaction.transaction_type
                    }
                ].concat(activities));

            if(response.data.transaction.drinking_user === user.sub) {
                updateTotalVolumen(totalVolumen + parseFloat(response.data.transaction.volume));
            }

            if(response.data.transaction.paying_user === user.sub) {
                updateBalance(balance - response.data.transaction.selling_price);
            }

            // update rank
            axios
                .get('/api/user/getRank')
                .then(function(response) {
                    // console.log(response.data.rank);
                    updateRank(response.data.rank);
                })
                .catch(function(error) {
                    updateRank('-');
                });

        })
        .catch((error) => {
            console.log(error);
        })

    }

    // when direnker dropdown is changed
    const handleDrinkerChange = (e) => {
        updateDrinkingUser(e.target.value)

        // if the user-role is baarkeeper or above
        if(user.role > 3) {
            // enable the checkbox
            updateSpesenDisabled(false);

            // uncheck the checkbox
            updateSpesen(false);
        }


        // if the user selected itsself, disable the spesen checkbox
        if(e.target.value === user.sub) {
            updateSpesenDisabled(true);
            updateSpesen(true);
        }
    }

    return(
        <Layout title="Dashboard">
            <div className="overview">

                <p>Hoi { Capitalize(user.name) }, do sind dini Wert im Überblick:</p>

                <div className="numbers">

                    <Liter litres={ totalVolumen } />

                    { user.ranking === '1' ? (<Rang rank={rank} />) : null }

                    <Guthaben balance={ balance } />
                
                </div>
            </div>

        <div className="last-activities">
            <p className="title">Letzte Aktivität</p>
            <div className="activities-container" onScroll={ TransactionscrollListener }>
               { activities.map(function(activity, index) {
                    return(
                            // GetHTMLFromTransaction(activity)
                            <DashboardTransaction transaction={activity} />
                    );
                }) }
                
                {
                    activities.length === 0 ? (
                        <p>Suuf mol meh!</p>
                    ) : ''
                } 
            </div>
        </div>

        <div className="consumption">

            <p>Gönn dir Was</p>
            
            <div id="drinks">
            {drinks.map(function(drink, index) {
                return(
                    <div className="drink" key={ index }>
                        
                        <MySlider index={ index } onSuccess={ () => {Consume(drink.id)} } color='#eeb800' text='SLIDE TO UNLOCK' />

                        <img src={ `${process.env.REACT_APP_API_URL}/uploads/${drink.image_file_name}` } alt=""></img>
                        <div className="price"> Fr { drink.selling_price }</div>
                    </div>
                );
            })}

        </div>

        <div className="settings">

            {/* <select name="drinker" id="drinker" onChange={ handleDrinkerChange } value={drinkingUser}>
                { users.map(function(user,index) {
                    
                    return(
                        <option value={ user.id } kex={ index }>{ Capitalize(user.name) }</option>
                    );
                }) }
            </select> */}

            <FormControl>
                <InputLabel id="drinker-label">Trinker</InputLabel>
                <Select
                labelId="drinker-label"
                value={drinkingUser}
                onChange={ handleDrinkerChange }
                >
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                { users.map(function(user,index) {
                    
                    return(
                        <MenuItem value={ user.id } key={ index }>{ Capitalize(user.name) }</MenuItem>
                    );
                }) }
                </Select>
            </FormControl>

            <div className="spesen">
                {/* <input type="checkbox" id="spesen" name="spesen" disabled={spesenDisabled} checked={spesen} onChange={ (e) => { updateSpesen(e.target.checked) } }></input>
                <label for="spesen">spendieren</label> */}

                <FormControlLabel
                    control={
                    <Checkbox
                        checked={spesen}
                        onChange={ (e) => { updateSpesen(e.target.checked) } }
                        name="spesen"
                        color="primary"
                        id="spesen"
                        disabled={spesenDisabled}
                    />
                    }
                    label="spendieren"
                />
            </div>
        </div>
        </div>

        <div className="confirmation">
        </div>

    </Layout>
    )
}

export default Dashboard;